import React, { forwardRef, useState, useEffect, useRef } from "react";

export default forwardRef(function TextInput(
    {
        value,
        name,
        placeholder,
        type = "text",
        className = "",
        hasError = false,
        onChange,
        trailingComponent = null,
        parentValue = null,
        label = null,
        isFocused = false,
    },
    ref
) {
    const input = ref ? ref : useRef();
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (isFocused) {
            input.current.focus();
        }
    }, []);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div className="form-group relative">
            {label && (
                <label
                    className={`block font-medium text-md text-astronaut-blue`}
                >
                    {label}
                </label>
            )}

            <input
                type={type}
                value={parentValue ? parentValue : value}
                name={name}
                ref={input}
                placeholder={placeholder}
                onChange={handleInputChange}
                className={`${className} h-8 bg-island-spice peer px-0
                ${
                    hasError
                        ? "border-2 border-red-500"
                        : "border-0 focus:ring-0"
                }
            `}
            />
            {trailingComponent}
            <div
                className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-astronaut-blue"
                aria-hidden="true"
            ></div>
        </div>
    );
});
